import React from "react";
import "./Footer.css";
const Footer = () => {
  return (
    <div className="Footer">
      <p>2024 | &copy; Royal L &amp; E Construction </p>
      <p>4965 Masekwas St, Olievenhoutbosch, Centurion, 0175</p>
    </div>
  );
};

export default Footer;